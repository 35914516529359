// https://kit.svelte.dev/docs/hooks#shared-hooks
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import { dev } from '$app/environment';
import { PUBLIC_BUILD_NAME } from '$env/static/public';

// polyfill for crypto.randomUUID
(() => {
    if (typeof crypto === 'undefined' || !crypto.randomUUID) {
        // window.crypto 객체가 없다면 정의
        if (typeof window !== 'undefined' && !window.crypto) {
            window.crypto = {};
        }

        const cryptoObj = typeof window !== 'undefined' ? window.crypto : crypto;

        // 새로운 UUID 생성 로직 적용
        cryptoObj.randomUUID = function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        };
    }
})();

if (!dev) {
    Sentry.init({
        dsn: 'https://cdba35afac9373f06545b96354a60594@o4505821044867073.ingest.sentry.io/4505821047947264',
        environment: PUBLIC_BUILD_NAME,
        integrations: [new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
            event.tags = {
                ...event.tags,
                build_date: __BUILD_DATE__,
                errorId: crypto.randomUUID(),
            };
            return event;
        },
    });
}

export async function handleError({ error, event }): Promise<HandleClientError> {
    const errorId = crypto.randomUUID();
    if (!dev) {
        Sentry.captureException(error, { extra: { event, errorId } });
    } else {
        console.warn('%c⚠️ handleError Event:', 'color: yellow; background: black; font-size: 16px', event);
        console.error('🔥', error);
    }

    if (error?.name === 'AxiosError') {
        // axios error 가 그대로 반환되는 경우 서버 에러가 그대로 노출 됨
        const status = error.response?.status || error.status;
        return {
            errorId,
            status,
            message: status === 404 ? get(_)('error.404') : status === 403 ? get(_)('error.403') : error?.message,
        };
    }
    return {
        message: error?.message,
        status: error?.status,
        errorId,
        // fail action이 throw 되면 extra data를 전달 받음 message, status 등 body에 extra 데이터가 담겨질 수 있음.
        ...error?.data,
    };
}

import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109')
];

export const server_loads = [];

export const dictionary = {
		"/[[lang=locale]]/(index-page)": [30,[2],[3]],
		"/[[lang=locale]]/(non-auth)/accept-invite": [104,[2,27],[3]],
		"/[[lang=locale]]/(need-unauth)/account-recovery": [99,[2,25],[3]],
		"/[[lang=locale]]/(non-auth)/esign": [105,[2,27,28],[3]],
		"/[[lang=locale]]/(non-auth)/esign/auth": [106,[2,27,28],[3]],
		"/[[lang=locale]]/(non-auth)/esign/exit": [107,[2,27,28],[3]],
		"/[[lang=locale]]/(non-auth)/esign/exit/[token]": [108,[2,27,28],[3]],
		"/[[lang=locale]]/(non-auth)/like-reload": [109,[2,27],[3]],
		"/[[lang=locale]]/(need-auth)/logout": [88,[2,4],[3]],
		"/[[lang=locale]]/(need-unauth)/reset-password-from-email": [100,[2,25],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-in": [101,[2,25,26],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up": [102,[2,25],[3]],
		"/[[lang=locale]]/(need-unauth)/super-admin": [103,[2,25],[3]],
		"/[[lang=locale]]/(need-auth)/su": [89,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/allibee-users": [90,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/allibee-users/form": [91,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/standardform-template": [92,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/super-users": [93,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/terms": [94,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/terms/[id]": [95,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/workspaces": [96,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/workspaces/form": [98,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/su/workspaces/[id]": [97,[2,4,24],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]": [31,[2,4,5],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)": [32,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/counterparty-list-management": [33,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/edit-permissions": [34,[2,4,5,6,7,8],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/notice": [35,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/notice/form": [37,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/notice/form/[id]": [38,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/notice/[id]": [36,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/payment": [39,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/seal-management": [40,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/user-list": [41,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/user-list/[tab]": [42,[2,4,5,6,7,9],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/workspace-info": [43,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/workspace-overview": [44,[2,4,5,6,7,10],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/workspace-overview/divisions": [45,[2,4,5,6,7,10],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/workspace-overview/positions": [46,[2,4,5,6,7,10],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/admin/(with-sidebar)/workspace-overview/titles": [47,[2,4,5,6,7,10],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/business-agent/(with-sidebar)": [48,[2,4,5,11],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/business-agent/(with-sidebar)/admin": [49,[2,4,5,11],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/approval": [52,[2,4,5,12,13,14],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/approval/draft/[contractId]": [61,[2,4,5,12,16],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/approval/[approvalId]": [60,[2,4,5,12,16],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/assignment": [53,[2,4,5,12,13,15],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/btls": [54,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/btls/new": [62,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/conhub": [63,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/conhub/[contractId]": [64,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/home": [55,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/my-request": [56,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/(new-contract)/new-contract-standard": [50,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/(new-contract)/new-contract-upload": [51,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/repository": [57,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/repository/contract-extract": [65,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/repository/detail/[id]": [66,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/repository/reexecute-copy": [67,[2,4,5,12],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/seal-documents": [58,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/clm/(with-sidebar)/upload-signed": [59,[2,4,5,12,13],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)": [68,[2,4,5,17],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/advisory": [69,[2,4,5,17,18],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/advisory/new": [71,[2,4,5,17,18,20],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/advisory/[id]": [70,[2,4,5,17,18,19],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/assignment": [72,[2,4,5,17],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/home": [73,[2,4,5,17],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/lawfirm": [74,[2,4,5,17,21],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/lawfirm/[id]": [75,[2,4,5,17,21],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations": [76,[2,4,5,17],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/new": [86,[2,4,5,17],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]": [77,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/commentsAndRecords": [80,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/email": [81,[2,4,5,17,22,23],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/email/[emailId]": [82,[2,4,5,17,22,23],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/expenseManagement": [83,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/fileManagement": [84,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/(supreme-court-connectable)/lawsuitGeneralContents": [78,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/(supreme-court-connectable)/lawsuitProgressedContents": [79,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/elm/(with-sidebar)/litigations/[id]/litigationRegistrationInformation": [85,[2,4,5,17,22],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/subscription-expired": [87,[2,4,5],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';